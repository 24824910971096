import {UserApi} from "../api_client/generated/User";

export const userService = {
    login,
    logout
}

function login(username, password) {
    return UserApi.authenticate.f(username, password).then(setAuthToken)
}

export function logout(reasonUnauthorized=false) {
    const loc = window.location.toString().toLowerCase()
    const isLoginUrl = loc.indexOf('/login') !== -1 || loc.indexOf('/logout') !== -1

    if (!isLoginUrl) {
        if (reasonUnauthorized) {
            console.log('Unauthorized')
            localStorage.setItem('redirAfterLogin', '/session')
        } else {
            console.log('Logging out')
            localStorage.setItem('redirAfterLogin', window.location.toString())
        }
    } else {
        localStorage.setItem('redirAfterLogin', '/session')
    }

    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpires')
}


export function setAuthToken({token, expires}) {
    localStorage.setItem('token', token)
    localStorage.setItem('tokenExpires', expires)
}
