import {http} from "../api";

const easyUnpack = ({data}) => data;
export const OrganizationApi = {
    create_organization: {
        f: function create_organization(name) {
            let url = '/organization';
            return http.post(url, {name}).then(easyUnpack)
        }
    },
    list_organizations: {
        f: function list_organizations() {
            let url = '/organization';
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    list_users: {
        f: function list_users(org_id) {
            let url = '/organization/$org_id/users';
            let parameterNames = ["$org_id"];
            [org_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    get_organization: {
        f: function get_organization(org_id) {
            let url = '/organization/$org_id';
            let parameterNames = ["$org_id"];
            [org_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    update_organization: {
        f: function update_organization(org_id, {name = null}) {
            let url = '/organization/$org_id';
            let parameterNames = ["$org_id"];
            [org_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.patch(url, {name}).then(easyUnpack)
        }
    },
    set_user_organization: {
        f: function set_user_organization(user_id, org_id) {
            let url = '/organization/$org_id/user/$user_id';
            let parameterNames = ["$user_id", "$org_id"];
            [user_id, org_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.put(url, {}).then(easyUnpack)
        }
    },
};
