export function getUTCDateAtFaceValue(date) {
    let val = new Date(date)
    const offset = new Date().getTimezoneOffset()
    val = val.setMinutes(val.getMinutes() + (offset*-1))
    return new Date(val)
}

export function prettyTimestampFromDate(date) {
    return date.toLocaleTimeString('en-US', {timeZone: 'UTC', hour12: false})
}

export function truncateLatLong(val) {
    return Math.trunc(val * 1e8) / 1e8;
}


export const prettyTimeDifference = (d1, d2) => {
    const diff = (d1 - d2)/1000 // seconds

    if (diff < 5) {
        return `${Math.floor(diff)} seconds ago.`
    }
    if (diff < 3600) {
        return `${Math.floor(diff/60)} minutes ago.`
    }
    if (diff < 86400) {
        return `About ${Math.floor(diff/3600)} hours ago.`
    }
    return `${Math.floor(diff/86400)} days ago.`
}
