import React from "react";
import {Route, BrowserRouter, Routes} from "react-router-dom";
import './index.css'

import './App.css';
import AdminPageWrapper from "./page/admin/AdminPageWrapper";
import AdminBoatPage from "./page/admin/boat-admin/AdminBoatPage";
import LoginPage from "./page/login/LoginPage";
import RequireAuth from "./auth/RequireAuth";
import Logout from "./page/login/Logout";
import AdminVideoPage from "./page/admin/video-admin/AdminVideoPage";
import HistoricDataPage from "./page/historic-data/HistoricDataPage";
import SessionPage from "./page/session/SessionPage";
import DataCalculator from "./page/data-calculator/DataCalculator";
import WaterProfileOverview from "./page/water-profile-overview/WaterProfileOverview";
import WaterProfilePage from "./page/water-profile-overview/water-profile-page/WaterProfilePage";
import LivePage from "./page/live/LivePage";
import OrganizationPage from "./page/organization/OrganizationPage";

function App() {
    return (<BrowserRouter>
        <Routes>
            <Route path='/login' element={<LoginPage/>}/>
            <Route path='/logout' element={<Logout/>}/>

            <Route path='/data-usage' element={<DataCalculator/>}/>

            <Route path='/' element={<RequireAuth><AdminPageWrapper/></RequireAuth>}>
                <Route path='/live' element={<RequireAuth><LivePage/></RequireAuth>}/>
                <Route path='/dashboard' element={<LivePage/>}/>
                <Route path='session' element={<HistoricDataPage/>}/>

                <Route path='water-profile' element={<WaterProfileOverview/>}/>
                <Route path='water-profile/:profileId' element={<WaterProfilePage/>}/>

                <Route path='session/:sessionId' element={<SessionPage/>}/>
                <Route path='boat' element={<AdminBoatPage/>}/>
                <Route path='video' element={<AdminVideoPage/>}/>

                <Route path='organization' element={<OrganizationPage/>}/>
            </Route>
        </Routes>
    </BrowserRouter>)
}

export default App;
