import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {DataSessionApi} from "../../api_client/generated/DataSession";
import './session-page.css';
import InputField from "../../component/input-field/InputField";
import {SensorReadingApi} from "../../api_client/generated/SensorReading";
import ProteusMap from "../../component/proteus-map/ProteusMap";
import SimpleButton from "../../component/simple-button/SimpleButton";
import InputCheckbox from "../../component/InputCheckbox/InputCheckbox";
import LineChart from "../../component/plots/LineChart";
import {SessionContext} from "./SessionContextProvider";
import ShowLessMore from "../../component/show-lessmore/ShowLessMore";
import {WaterProfileMetricApi} from "../../api_client/generated/WaterProfileMetric";
import {WaterProfileApi} from "../../api_client/generated/WaterProfile";
import burgerMenu from '../../assets/burger-menu-icon.png';
import {
    calculateDistanceForSamples,
} from "../../component/proteus-map/NormalizeHeatmapData";
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";
import {useSessionData} from "./useSessionData";
import EditSessionPoup from "./edit_session_popup";
import AnnotationManager from "./annotation/AnnotationManager";
import SessionSummary from "./session-summary/SessionSummary";
import SessionDataTable from "./SessionDataTable";

export default function SessionPage() {
    const [endDate, setEndDate] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [filters, setFilters] = useState({inWater: r => r.properties.conductivity > 10000});

    const [smallGraphs, setSmallGraphs] = useState(true);
    const [sessionEdit, setSessionEdit] = useState(null);

    const [dataDescription, setDataDescription] = useState(null);

    const [waterProfiles, setWaterProfiles] = useState(null);
    const [selectedWaterProfile, setSelectedWaterProfile] = useState(null);
    const [waterMetrics, setWaterMetrics] = useState(null);

    const [selectedTab, setSelectedTab] = useState('detailed-view');
    const [rightsideTab, setRightsideTab] = useState('graphs');
    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        SensorReadingApi.describe_measurements.f({}).then(setDataDescription);
        WaterProfileApi.list_water_profiles.f().then(setWaterProfiles);
    }, []);

    const {sessionId} = useParams();

    const {
        session,
        setSession,
        setHoveringX,
        selectedDataItem,
        setSelectedDataItem,
        selectedPeriodStart,
        selectedPeriodEnd,
        setSelectedPeriodStart,
        setSelectedPeriodEnd,
        setDisplayIncident,
    } = useContext(SessionContext);

    const {
        sessionData, filteredSessionData, loadingData, fetchData,
    } = useSessionData(filters);

    const traveledDistance = useMemo(() => {
        return filteredSessionData ? calculateDistanceForSamples(filteredSessionData) : 0;
    }, [filteredSessionData]);

    let [searchParams, _] = useSearchParams();
    searchParams = Object.fromEntries(searchParams.entries());
    const {focus_start, focus_end} = searchParams;

    useEffect(() => {
        if (!focus_start || !focus_end) return;
        if (focus_start) {
            const s = new Date(focus_start)
            setSelectedPeriodStart(prev => prev === null ? s : prev);
        }
        if (focus_end) {
            const e = new Date(focus_end)
            setSelectedPeriodEnd(prev => prev === null ? e : prev);
        }
        if (focus_start && focus_end) setDisplayIncident(true);
    }, [setSelectedPeriodStart, setSelectedPeriodEnd, setDisplayIncident, focus_start, focus_end]);

    const parameters = useMemo(() => {
        const k = [{
            key: 'timestamp', title: 'Timestamp', dont_plot: true
        }, {
            key: 'salinity', title: 'Salinity',
        }, {
            key: 'tryptophan', title: 'Tryptophan',
        }, {
            key: 'conductivity', title: 'Conductivity',
        }, {
            key: 'temperature', title: 'Temperature',
        }, {
            key: 'turbidity', title: 'Turbidity',
        }, {
            key: 'cdom', title: 'CDOM',
        }, {
            key: 'ph', title: 'pH',
        }, {
            key: 'orp', title: 'ORP',
        }, {
            key: 'longitude', title: 'Longitude', dont_plot: true
        }, {
            key: 'latitude', title: 'Latitude', dont_plot: true
        }]
        if (waterMetrics === null) return k;

        return k.map(({key, title, ...rest}) => {
            const metric = waterMetrics.find(m => m.parameter === key);
            if (!metric) return {key, title, ...rest};
            return {
                key, title, metric, ...rest
            }
        })
    }, [waterMetrics]);

    useEffect(() => {
        DataSessionApi.get_session.f(sessionId).then(setSession);
    }, [sessionId, setSession]);

    useEffect(() => {
        if (!selectedDataItem) return;
        const row = document.getElementById(`row-${selectedDataItem.id}`);
        if (!row) return;
        row.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }, [selectedDataItem]);

    const clearSelection = useCallback(_ => {
        setSelectedPeriodStart(null);
        setSelectedPeriodEnd(null);
        setDisplayIncident(false);
    }, [setSelectedPeriodStart, setSelectedPeriodEnd, setDisplayIncident]);

    useKeyboardShortcut({
        shortcutKeys: ['Escape'], callback: _ => {
            clearSelection();
            navigate(`/session/${sessionId}`)
            setSelectedDataItem(null)
            setHoveringX(null)
        }
    });
    useKeyboardShortcut({
        shortcutKeys: ['Enter'], callback: _ => {
            setDisplayIncident(true)
            // set params for incident start and end
            let s = selectedPeriodStart.toISOString()
            let e = selectedPeriodEnd.toISOString()
            navigate(`/session/${sessionId}?focus_start=${s}&focus_end=${e}`)
        }
    });

    if (!session) return <div>Loading...</div>;

    return <div className='session-page'>
        <EditSessionPoup
            sessionEdit={sessionEdit}
            sessionId={sessionId}
            setSession={setSession}
            setSessionEdit={setSessionEdit}/>
        <div className='session-header'>
            {selectedTab !== 'summary' && <div>
                <h2>{session.name}</h2>
                <sub>{session.session_start_timestamp} - {session.session_end_timestamp}</sub>
            </div>}
            <div className='tab-wrapper'>
                <div className={`tab ${selectedTab === 'detailed-view' ? 'selected' : ''}`}
                     onClick={_ => setSelectedTab('detailed-view')}>Detailed view
                </div>
                <div className={`tab ${selectedTab === 'summary' ? 'selected' : ''}`}
                     onClick={_ => setSelectedTab('summary')}>Summary
                </div>
            </div>
            <div className='grower'/>
            <label className='dont-print'>Tryk 'ESCAPE' for at fjerne filtre</label>
            <label className='dont-print'>Tryk 'ENTER' for at submit et valg</label>
            <div className='grower'/>
            <div className='burger-menu-wrapper'>
                <img className='drop-down-menu-button' alt='burger-menu-drop-down' src={burgerMenu}
                     onClick={_ => setBurgerMenuOpen(p => !p)}/>
                {burgerMenuOpen && <div className='burger-menu'>
                    <button onClick={_ => {
                        setSessionEdit(session)
                    }}>Edit session
                    </button>
                    <label htmlFor='only-in-water'>Only in-water samples
                        <InputCheckbox
                            value={filters.inWater !== null}
                            id='only-in-water'
                            onChanged={checked => {
                                setFilters(prev => ({
                                    ...prev, inWater: checked ? r => r.properties.conductivity > 10000 : null
                                }))
                            }}
                        />
                    </label>

                    <SimpleButton value='Export to CSV' onClick={_ => {
                        const keys = [...parameters.map(k => k.key), 'longitude', 'latitude'];
                        let csv = [...keys, 'QANiveau'].join(';') + '\n'
                        csv += filteredSessionData.map(d => keys.map(k => d.properties[k]).join(';') + `;Fagligt Forbehold`).join('\n')
                        const blob = new Blob([csv], {type: 'text/csv'})
                        const url = URL.createObjectURL(blob)
                        const a = document.createElement('a')
                        a.href = url
                        a.download = `${session.name}.csv`
                        a.click()
                        URL.revokeObjectURL(url)
                    }}/>
                    {session.session_end_timestamp === null && <div>
                        <InputField value={endDate}
                                    onChanged={setEndDate}
                                    title='Set end timestamp'
                                    type='date'
                        />
                        <InputField value={endTime}
                                    onChanged={setEndTime}
                                    type='time'
                        />
                        <input type='button' value='click to end' onClick={_ => {
                            DataSessionApi.update_session.f(sessionId, {
                                timestamp_end: `${endDate}T${endTime}:00Z`
                            }).then(() => {
                                DataSessionApi.get_session.f(sessionId).then(setSession);
                            })
                        }}/>
                    </div>}
                    <SimpleButton loading={loadingData} value='Get newest data' onClick={fetchData}/>

                    <InputField onlySelectableOptions
                                title={'Select water profile'}
                                options={waterProfiles?.map(wp => wp.name)}
                                value={selectedWaterProfile?.name}
                                onChanged={val => {
                                    const profile = waterProfiles.find(wp => wp.name === val);
                                    setSelectedWaterProfile(profile);
                                    WaterProfileMetricApi.list_water_profile_metrics.f(profile.id, {with_tags: true}).then(setWaterMetrics);
                                }}
                    />
                </div>}
            </div>
        </div>
        {selectedTab === 'summary' && (<SessionSummary
            session={session}
            filteredSessionData={filteredSessionData}
            parameters={parameters}
            dataDescription={dataDescription}
            traveledDistance={traveledDistance}
        />)}
        {sessionData && sessionData.length > 0 && selectedTab === 'detailed-view' && <main>
            <div className='left-side'>
                {filteredSessionData && filteredSessionData.length > 0 && <div className='map-video-wrapper'>
                    <ProteusMap filteredSessionData={filteredSessionData}
                                height={'45vh'}
                                allow_pan={true}
                                displayKeys={parameters.filter(p => !p.dont_plot).map(k => k.key)}
                    />
                </div>}
                <SessionDataTable filteredSessionData={filteredSessionData}
                                  parameters={parameters}
                                  dataDescription={dataDescription}
                />
            </div>
            <div className='right-side'>
                <div className='tab-wrapper'>
                    <div className={`tab ${rightsideTab === 'graphs' ? 'selected' : ''}`}
                         onClick={_ => setRightsideTab('graphs')}>Graphs
                    </div>
                    <div className={`tab ${rightsideTab === 'annotations' ? 'selected' : ''}`}
                         onClick={_ => setRightsideTab('annotations')}>Annotations
                    </div>
                </div>

                {rightsideTab === 'annotations' && <AnnotationManager canCreateNew/>}
                {rightsideTab === 'graphs' && <div className='graph-wrapper'>
                    <InputCheckbox value={smallGraphs} onChanged={setSmallGraphs} title='Small graphs'/>
                    {filteredSessionData && filteredSessionData.length > 0 &&
                        <div className={`graphs ${smallGraphs ? 'small' : ''}`}>
                            {parameters.filter(p => !p.dont_plot).map(({title, key, metric}) => {
                                const description = dataDescription ? dataDescription[key] : null;

                                return (<div key={key} className=''>
                                    <h2>{title}{description && <span>{description?.unit}</span>}</h2>
                                    {description?.derived_from.length > 0 && <ShowLessMore>
                                        <h4>-> {description?.derived_from.join(', ')}</h4>
                                    </ShowLessMore>}
                                    <div className='key-number-wrapper'>
                                        {/*<label>avg: {Math.floor(filteredSessionData.map(d => d.properties[key]).reduce((a, b) => a + b, 0) / filteredSessionData.length * 10) / 10}</label>*/}
                                        {/*<label>min: {Math.min(...filteredSessionData.map(d => d.properties[key]))}</label>*/}
                                        {/*<label>max: {Math.max(...filteredSessionData.map(d => d.properties[key]))}</label>*/}
                                        {/*<label>Mean: {filteredSessionData.map(d => d.properties[key]).sort()[Math.floor(filteredSessionData.length / 2)]}</label>*/}
                                    </div>
                                    {/*{key !== 'turbidity' && <BoxPlot data={filteredSessionData.map(d => d.properties[key])}/>}*/}
                                    <LineChart
                                        id={key}
                                        width={smallGraphs ? 250 : 450}
                                        height={smallGraphs ? 200 : 450}
                                        data_key={key}
                                        all_data={filteredSessionData}
                                        mean={metric?.mean} std_deviation={metric?.std_deviation}
                                    />
                                </div>)
                            })}
                        </div>}
                </div>}
            </div>
        </main>}
    </div>
}
