import React, {useCallback, useEffect, useMemo, useState} from "react";
import {SensorReadingApi} from "../../api_client/generated/SensorReading";
import ProteusMap from "../../component/proteus-map/ProteusMap";
import './live-page.css';
import {VideoApi} from "../../api_client/generated/Video";
import {prettyTimeDifference, truncateLatLong} from "../../helpers";
import MultiLineChart from "../../component/plots/MultiLineChart";
import AnnotationManager from "../session/annotation/AnnotationManager";
import InputField from "../../component/input-field/InputField";
import SimpleButton from "../../component/simple-button/SimpleButton";
import BasicPopupWindow from "../../component/basic-popup-window/BasicPopupWindow";
import {useSessionData} from "../session/useSessionData";

export default function LivePage() {
    const [sensorData, setSensorData] = useState(null);

    const [latestImages, setLatestImages] = useState({});

    const [fetchLiveImages, setFetchLiveImages] = useState(false);

    const [recentMapSettings, setRecentMapSettings] = useState({
        lastNMinutes: 30,
        popup: false,
    });

    const {sessionData, setSessionData} = useSessionData();

    const fetchData = useCallback(() => {
        SensorReadingApi.most_recent_data.f({}).then(ds => {
            const dt = ds.map((d) => ({
                ...d, properties: {
                    ...d.properties,
                    id: d.id,
                    raw_timestamp: d.properties.timestamp,
                    timestamp: new Date(d.properties.timestamp),
                    timestamp_iso: new Date(d.properties.timestamp).toISOString(),
                    latitude: truncateLatLong(d.properties.latitude),
                    longitude: truncateLatLong(d.properties.longitude),
                },
            }))
            dt.sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp));

            // Remove spikes, if turbidity rises with more than 100 from the previous value, remove it
            let prevTurbidity = null;
            dt.forEach((d) => {
                const r = d.properties.turbidity;
                if (prevTurbidity !== null && d.properties.turbidity > (prevTurbidity * 10)) {
                    d.properties.turbidity = prevTurbidity;
                }
                prevTurbidity = r;
            });

            setSensorData(dt);
        });
        VideoApi.fetch_latest_image.f('mast').then(im => setLatestImages(prev => ({
            ...prev,
            mast: im
        })));
        VideoApi.fetch_latest_image.f('keel').then(im => setLatestImages(prev => ({
            ...prev,
            keel: im
        })));
    }, []);

    const latestSensorData = useMemo(() => {
        if (!sensorData) return null;
        return sensorData[0]
    }, [sensorData]);

    useEffect(() => {
        fetchData()
        // Fetch data every 5 seconds
        if (fetchLiveImages) {
            const intervalId = setInterval(() => {
                fetchData()
            }, 5000);
            return () => clearInterval(intervalId);
        }
    }, [fetchData, fetchLiveImages]);

    const getMapData = useCallback((n_min) => {
        const session_start_timestamp = new Date(new Date().setMinutes(new Date().getMinutes() - n_min))
        const session_end_timestamp = new Date(new Date().setMinutes(new Date().getMinutes()))
        SensorReadingApi.list_sensor_data.f({
            timestamp_from: session_start_timestamp,
            timestamp_to: session_end_timestamp,
            only_in_water: true,
        }).then(ds => {
            const dt = ds.map((d) => ({
                ...d, properties: {
                    ...d.properties,
                    id: d.id,
                    raw_timestamp: d.properties.timestamp,
                    timestamp: new Date(d.properties.timestamp),
                    timestamp_iso: new Date(d.properties.timestamp).toISOString(),
                    latitude: truncateLatLong(d.properties.latitude),
                    longitude: truncateLatLong(d.properties.longitude),
                },
            }))
            dt.sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp));

            setSessionData(dt)
        })
    }, [setSessionData]);


    return <div className='live-page'>
        <div className='image-container'>
            <div className='img-wrapper'>
                <img src={latestImages.mast} alt='mast'/>
                <label>mast</label>
            </div>
            <div className='img-wrapper'>
                <img src={latestImages.keel} alt='keel'/>
                <label>keel</label>
            </div>
        </div>
        <main>
            <section>
                <h4>Fetch live images</h4>
                {fetchLiveImages &&
                    <SimpleButton
                        value='Stop live images'
                        red
                        onClick={() => setFetchLiveImages(false)}/>}
                {!fetchLiveImages &&
                    <SimpleButton
                        value='Start live images'
                        onClick={() => setFetchLiveImages(true)}/>}
            </section>

            <section className='horizontal'>
                <AnnotationManager canCreateNew/>
                <div>
                    <h4>Get map overview</h4>
                    <InputField
                        title='Last N minutes'
                        type={'number'}
                        value={recentMapSettings.lastNMinutes}
                        onChanged={(e) => setRecentMapSettings(prev => ({
                            ...prev,
                            lastNMinutes: e
                        }))}/>
                    <SimpleButton
                        value='Show map'
                        onClick={() => {
                            getMapData(recentMapSettings.lastNMinutes)
                            setRecentMapSettings(prev => ({
                                ...prev,
                                popup: true
                            }))
                        }}/>
                </div>
                <BasicPopupWindow showPopup={recentMapSettings.popup} closePopup={() => setRecentMapSettings(prev => ({
                    ...prev,
                    popup: false,
                    data: null
                }))}>
                    <ProteusMap
                        filteredSessionData={sessionData}
                        height={'80vh'}
                        allow_pan
                        showLegend
                        displayKeys={['tryptophan', 'salinity']}
                        displaySettings
                        selectedDatapoint={null}
                        setSelectedDatapoint={null}
                    />
                </BasicPopupWindow>
            </section>
            <div className='timestamp'>
                {latestSensorData && Object
                    .entries(latestSensorData.properties)
                    .filter(([key, value]) => key === 'timestamp')
                    .map(([key, value]) =>
                        <>
                            <h2 className='value'>{value.toISOString()}</h2>
                            <h4>{prettyTimeDifference(new Date(value), new Date().setHours(new Date().getHours() - 1))}</h4>
                        </>
                    )}
            </div>
            <div className='metrics'>
                {latestSensorData && Object
                    .entries(latestSensorData.properties)
                    .filter(([key, value]) => ['bod', 'cdom', 'orp', 'ph', 'salinity', 'temperature', 'tryptophan', 'turbidity', 'conductivity'].includes(key))
                    .map(([key, value]) =>
                        <div className='metric'>
                            <p className='title'>{key}</p>
                            <h2 className='value'>{value}</h2>
                        </div>
                    )}
            </div>

            {sensorData && <MultiLineChart
                id={`annotation-live-mult`}
                dataSeries={[{
                    name: 'Tryptophan',
                    color: 'red',
                    data: sensorData.map(d => ({
                        value: d.properties.tryptophan,
                        timestamp: new Date(d.properties.timestamp)
                    })),
                    xKey: 'timestamp',
                    yKey: 'value',
                    yAxisPosition: 'left'
                }, {
                    name: 'Salinity',
                    color: 'blue',
                    data: sensorData.map(d => ({
                        value: d.properties.salinity,
                        timestamp: new Date(d.properties.timestamp)
                    })),
                    xKey: 'timestamp',
                    yKey: 'value',
                    yAxisPosition: 'right'
                }]}
                width={window.innerWidth > 800 ? 800 : window.innerWidth}
                height={200}
            />}

            {sensorData && <MultiLineChart
                id={`annotation-live-mult`}
                dataSeries={[{
                    name: 'Temperature',
                    color: 'green',
                    data: sensorData.map(d => ({
                        value: d.properties.temperature,
                        timestamp: new Date(d.properties.timestamp)
                    })),
                    xKey: 'timestamp',
                    yKey: 'value',
                    yAxisPosition: 'right'
                }, {
                    name: 'cdom',
                    color: 'purple',
                    data: sensorData.map(d => ({
                        value: d.properties.cdom,
                        timestamp: new Date(d.properties.timestamp)
                    })),
                    xKey: 'timestamp',
                    yKey: 'value',
                    yAxisPosition: 'left'
                }]}
                width={window.innerWidth > 800 ? 800 : window.innerWidth}
                height={200}
            />}
        </main>
    </div>
}
