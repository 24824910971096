import React, {createContext, useMemo, useState} from "react";

export const SessionContext = createContext(null);

export const SessionContextProvider = ({children}) => {
    const [session, setSession] = useState(null);
    const [sessionData, setSessionData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [hoveringX, setHoveringX] = useState(null);
    const [selectedDataItem, setSelectedDataItem] = useState(null);
    const [selectedPeriodStart, setSelectedPeriodStart] = useState(null);
    const [selectedPeriodEnd, setSelectedPeriodEnd] = useState(null);
    const [displayIncident, setDisplayIncident] = useState(false);

    const [annotations, setAnnotations] = useState([]);
    const [editAnnotation, setEditAnnotation] = useState(null);

    const [colorIndicator, setColorIndicator] = useState('tryptophan');
    const [_minIndicatorValue, setMinIndicatorValue] = useState(22);
    const [_maxIndicatorValue, setMaxIndicatorValue] = useState(26);

    const [relativeColorValues, setRelativeColorValues] = useState(true);

    const {minIndicatorValue, maxIndicatorValue} = useMemo(() => {
        if (!sessionData) return {minIndicatorValue: _minIndicatorValue, maxIndicatorValue: _maxIndicatorValue};

        let values = sessionData.map((point) => point.properties[colorIndicator]);
        values = values.sort((a, b) => a - b);

        const indicatorMin = relativeColorValues ? values[3] : _minIndicatorValue
        const indicatorMax = relativeColorValues ? values[values.length - 3] : _maxIndicatorValue

        return {minIndicatorValue: indicatorMin, maxIndicatorValue: indicatorMax}
    }, [sessionData, relativeColorValues, _minIndicatorValue, _maxIndicatorValue, colorIndicator]);

    return <SessionContext.Provider value={{
        hoveringX,
        setHoveringX,

        selectedDataItem,
        setSelectedDataItem,

        session,
        setSession,

        sessionData,
        setSessionData,

        loadingData,
        setLoadingData,

        loadingVideos,
        setLoadingVideos,

        selectedPeriodStart,
        setSelectedPeriodStart,

        selectedPeriodEnd,
        setSelectedPeriodEnd,

        displayIncident,
        setDisplayIncident,

        annotations,
        setAnnotations,

        editAnnotation,
        setEditAnnotation,

        colorIndicator,
        setColorIndicator,

        minIndicatorValue,
        setMinIndicatorValue,

        maxIndicatorValue,
        setMaxIndicatorValue,

        relativeColorValues,
        setRelativeColorValues,
    }}>
        {children}
    </SessionContext.Provider>
}
