import {useState, useEffect, useCallback, useMemo, useContext} from "react";
import {SensorReadingApi} from "../../api_client/generated/SensorReading";
import {SessionContext} from "./SessionContextProvider";
import {truncateLatLong} from "../../helpers";
import chroma from "chroma-js";

export function useSessionData(filters = null) {
    const {
        selectedPeriodStart, selectedPeriodEnd,
        displayIncident,
        session,
        sessionData, setSessionData,
        loadingData, setLoadingData,
        minIndicatorValue,
        maxIndicatorValue,
        colorIndicator,
    } = useContext(SessionContext);

    const [fetchedDataFromSessSettings, setFetchedDataFromSessSettings] = useState({
        session_start_timestamp: null,
        session_end_timestamp: null,
    });

    // Fetch the session data when the session is available
    const fetchData = useCallback(() => {
        if (!session || !session.session_end_timestamp) return;
        if (sessionData !== null) return;
        if (loadingData) return;
        if (fetchedDataFromSessSettings.session_start_timestamp === session.session_start_timestamp
            && fetchedDataFromSessSettings.session_end_timestamp === session.session_end_timestamp)
            return;

        console.log('Fetching session data', session);

        const filterFactor = 10;

        setLoadingData(true);

        SensorReadingApi.list_sensor_data.f({
            timestamp_from: session.session_start_timestamp,
            timestamp_to: session.session_end_timestamp,
            only_in_water: false,
        })
            .then((ds) => {
                setLoadingData(false);
                setFetchedDataFromSessSettings({
                    session_start_timestamp: session.session_start_timestamp,
                    session_end_timestamp: session.session_end_timestamp,
                });
                if (ds === null) return;
                const data = ds.map((d) => ({
                    ...d, properties: {
                        ...d.properties,
                        id: d.id,
                        timestamp: new Date(d.properties.timestamp),
                        timestamp_iso: new Date(d.properties.timestamp).toISOString(),
                        latitude: truncateLatLong(d.properties.latitude),
                        longitude: truncateLatLong(d.properties.longitude),
                    },
                }))
                    .sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp))

                // Remove spikes, if turbidity rises with more than 100 from the previous value, remove it
                let prevTurbidity = null;
                data.forEach((d) => {
                    const r = d.properties.turbidity;
                    if (prevTurbidity !== null && d.properties.turbidity > (prevTurbidity * filterFactor)) {
                        d.properties.turbidity = prevTurbidity;
                    }
                    prevTurbidity = r;
                });

                setSessionData(data);
            });
    }, [session, sessionData, loadingData, fetchedDataFromSessSettings.session_start_timestamp, fetchedDataFromSessSettings.session_end_timestamp, setLoadingData, setSessionData]);

    useEffect(() => {
        if (session && session.session_end_timestamp) {
            fetchData();
        }
    }, [session, fetchData]);

    // Compute the filtered session data
    const filteredSessionData = useMemo(() => {
        if (!sessionData) {
            return null;
        }
        let res = sessionData;

        if (filters !== null) {
            res = res
                .filter((r) => Object.entries(filters).every(([key, filter]) => {
                    if (!filter) return true;
                    return filter(r);
                }));
        }

        if (!res) {
            console.log('filteredSessionData empty after filters')
            return null;
        }

        if (displayIncident && selectedPeriodStart !== null && selectedPeriodEnd !== null) {
            const f = Math.min(selectedPeriodStart, selectedPeriodEnd);
            const t = Math.max(selectedPeriodStart, selectedPeriodEnd);
            res = res.filter((r) => new Date(r.properties.timestamp) >= f && new Date(r.properties.timestamp) <= t);
        }

        return res
            .sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp));
    }, [sessionData, filters, selectedPeriodStart, selectedPeriodEnd, displayIncident,]);

    // item colors
    const itemColors = useMemo(() => {
        if (!filteredSessionData) return {};

        const safeColor = '#9FFF9F';
        // const safeColor = '#85e89d';
        const severeColor = '#e02f29';

        const res = {}
        filteredSessionData.forEach((item) => {
            const value = item['properties'][colorIndicator];

            let background = 'white';
            let color = 'black';
            if (value <= minIndicatorValue) background = safeColor;
            else if (value >= maxIndicatorValue) background = severeColor;
            else {

                // Calculate the interpolation factor
                const factor = (value - minIndicatorValue) / (maxIndicatorValue - minIndicatorValue);

                // Interpolate color
                background = chroma.mix(safeColor, severeColor, factor).hex();
                color = factor > 0.8 ? 'white' : 'black'
            }

            res[item.id] = {
                background,
                color
            };
        });
        return res;
    }, [filteredSessionData, minIndicatorValue, maxIndicatorValue, colorIndicator]);

    return {
        sessionData,
        filteredSessionData,
        loadingData,
        setSessionData,
        setLoadingData,
        fetchData,
        itemColors,
    };
}
