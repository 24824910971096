import React, {useEffect, useState} from "react";
import './historic-data-page.css';
import {DataSessionApi} from "../../api_client/generated/DataSession";
import SimpleButton from "../../component/simple-button/SimpleButton";
import InputField from "../../component/input-field/InputField";
import {OrganizationApi} from "../../api_client/generated/Organization";
import {UserApi} from "../../api_client/generated/User";
import BasicPopupWindow from "../../component/basic-popup-window/BasicPopupWindow";

export default function HistoricDataPage() {
    const [organizations, setOrganizations] = useState(null);
    const [dataSessions, setDataSessions] = useState(null);
    const [dataSessionSearch, setDataSessionSearch] = useState('');
    const [newSessionInfo, setNewSessionInfo] = useState({
        name: '',
        date: '',
        timestamp: '',
        popup: false
    });
    const [user, setUser] = useState(null);

    useEffect(() => {
        UserApi.get_me.f().then(setUser);
        DataSessionApi.list_sessions.f().then(setDataSessions)
        OrganizationApi.list_organizations.f().then(setOrganizations).catch(console.error)
    }, []);

    return (<div className='historic-data-page'>
        <BasicPopupWindow showPopup={newSessionInfo.popup}
                          className='create-new-session'
                          closePopup={() => setNewSessionInfo(p => ({...p, popup: false}))}>
            {user && user.scope === 'employee' && <>
                <h4>Create a new session</h4>
                <InputField title='Session name'
                            onChanged={val => setNewSessionInfo(p => ({...p, name: val}))}
                />
                <InputField title='Date'
                            type='date'
                            onChanged={val => setNewSessionInfo(p => ({...p, date: val}))}
                />
                <InputField title='Timestamp'
                            type='time'
                            onChanged={val => setNewSessionInfo(p => ({...p, timestamp: val}))}
                />
                <SimpleButton value='Create New Session' onClick={() => {
                    DataSessionApi.create_session.f(
                        newSessionInfo.name,
                        `${newSessionInfo.date}T${newSessionInfo.timestamp}:00Z`
                    ).then(() => {
                        DataSessionApi.list_sessions.f().then(setDataSessions)
                    })
                }}/>
            </>}
        </BasicPopupWindow>
        <div>
            <h1>Historic Data</h1>
            {user && user.scope === 'employee' && <SimpleButton value={'Create New Session'} onClick={() => setNewSessionInfo(p => ({...p, popup: true}))}/>}
        </div>
        <InputField title='Search'
                    onChanged={setDataSessionSearch}
                    value={dataSessionSearch}/>
        {dataSessions !== null && <table className='sessions'>
            <thead>
            <tr>
                <th>Session</th>
                <th>Organization</th>
                <th>From</th>
                <th>To</th>
            </tr>
            </thead>
            <tbody>
            {dataSessions
                .filter(d => {
                    const nameMatch = d.name.toLowerCase().includes(dataSessionSearch.toLowerCase())

                    let orgMatch = false;
                    const org = organizations?.find(o => o.id === d.organization_id)
                    if (org) {
                        orgMatch = org.name.toLowerCase().includes(dataSessionSearch.toLowerCase())
                    }
                    return nameMatch || orgMatch
                })
                .sort((d1, d2) => d2.id - d1.id).map(session =>
                <tr key={session.id} className='session'>
                    <td><a href={`/session/${session.id}`}>{session.name}</a></td>
                    {user && user.scope === 'employee' && <td>
                        <InputField disabled={organizations === null}
                                    value={organizations?.find(o => o.id === session.organization_id)?.name}
                                    options={organizations?.map(o => o.name) || []}
                                    onlySelectableOptions
                                    onChanged={val => {
                                        const org = organizations.find(o => o.name === val);
                                        if (org) {
                                            DataSessionApi.update_session.f(session.id, {organization_id: org.id}).then(() => {
                                                DataSessionApi.list_sessions.f().then(setDataSessions)
                                            })
                                        }
                                    }}/></td>}
                    {user && user.scope === 'client' && <td>
                        {organizations && organizations.find(o => o.id === session.organization_id)?.name}
                    </td>}
                    <td>{session.session_start_timestamp}</td>
                    <td>{session.session_end_timestamp}</td>
                    {user && user.scope === 'employee' && <td>
                        <SimpleButton white value={'Delete'} onClick={() => {
                            if (window.confirm('Are you sure you want to delete this session?')) {
                                DataSessionApi.delete_session.f(session.id).then(() => {
                                    DataSessionApi.list_sessions.f().then(setDataSessions)
                                })
                            }
                        }}/>
                    </td>}
                </tr>)}
            </tbody>
            {dataSessions.length === 0 && <tr>
                <td>No sessions found</td>
            </tr>}
        </table>}
        {dataSessions === null && <p>Loading...</p>}
    </div>)
}
