import React, {useEffect, useState, useCallback} from "react";
import SimpleButton from "../../component/simple-button/SimpleButton";
import InputField from "../../component/input-field/InputField";
import {OrganizationApi} from "../../api_client/generated/Organization";
import {UserApi} from "../../api_client/generated/User";
import BasicPopupWindow from "../../component/basic-popup-window/BasicPopupWindow";
import './organization.css';

export default function OrganizationPage() {
    const [organizations, setOrganizations] = useState(null);
    const [newOrganizationName, setNewOrganizationName] = useState('');
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [selectedOrganizationUsers, setSelectedOrganizationUsers] = useState(null);

    const [creatingNewOrganization, setCreatingNewOrganization] = useState(false);

    const [allUsers, setAllUsers] = useState(null);

    useEffect(() => {
        OrganizationApi.list_organizations.f().then(setOrganizations);
        UserApi.list_users.f().then(setAllUsers);
    }, []);

    const selectOrganization = useCallback((organization) => {
        setSelectedOrganization(organization)
        OrganizationApi.list_users.f(organization.id).then(setSelectedOrganizationUsers);
    }, [setSelectedOrganization, setSelectedOrganizationUsers]);

    if (organizations === null) return <p>Loading...</p>

    return (<div className='organization-page'>
        <BasicPopupWindow showPopup={creatingNewOrganization} closePopup={setCreatingNewOrganization}
                          className='create-new-session'>
            <h4>Create new organization</h4>
            <InputField title='Organization name'
                        onChanged={setNewOrganizationName}
            />
            <SimpleButton value='Create new organization' onClick={() => {
                OrganizationApi.create_organization.f(
                    newOrganizationName
                ).then(() => {
                    setCreatingNewOrganization(false)
                    OrganizationApi.list_organizations.f().then(setOrganizations)
                })
            }}/>
        </BasicPopupWindow>
        <SimpleButton value='Create new organization' onClick={() => setCreatingNewOrganization(true)}/>
        <div className='horizontal'>
            <div className='org-list'>
                <h1>Organizations</h1>
                {organizations.sort((d1, d2) => d2.id - d1.id).map(organization =>
                    <div key={organization.id}
                         onClick={_ => selectOrganization(organization)}
                         className={`organization ${organization.id === selectedOrganization?.id ? 'selected' : ''}`}>
                        <h4>{organization.name}</h4>
                    </div>)}
            </div>
            <div className={'org-details'}>
                {selectedOrganization && <div>
                    <h2>{selectedOrganization.name}</h2>
                    <h3>Users</h3>
                    {selectedOrganizationUsers && <div>
                        {selectedOrganizationUsers.map(user => <div key={user.id}>
                            <h4>{user.username}</h4>
                        </div>)}
                    </div>}
                </div>}
                {selectedOrganization && <InputField
                    title='Tilføj bruger'
                    options={allUsers?.map(user => user.username)}
                    onlySelectableOptions
                    onChanged={name => {
                        const user = allUsers?.find(u => u.username === name)
                        if (!user) return;
                        OrganizationApi.set_user_organization.f(user.id, selectedOrganization.id).then(() => {
                            OrganizationApi.list_users.f(selectedOrganization.id).then(setSelectedOrganizationUsers)
                        })
                    }}
                />}
            </div>
        </div>
    </div>)
}
