import {http} from "../api";

const easyUnpack = ({data}) => data;
export const DataSessionApi = {
    create_session: {
        f: function create_session(name, timestamp) {
            let url = '/data-session';
            return http.post(url, {name, timestamp}).then(easyUnpack)
        }
    },
    list_sessions: {
        f: function list_sessions() {
            let url = '/data-session';
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    get_session: {
        f: function get_session(session_id) {
            let url = '/data-session/$session_id';
            let parameterNames = ["$session_id"];
            [session_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    update_session: {
        f: function update_session(session_id, {
            name = null,
            timestamp_start = null,
            timestamp_end = null,
            organization_id = null
        }) {
            let url = '/data-session/$session_id';
            let parameterNames = ["$session_id"];
            [session_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.patch(url, {name, timestamp_start, timestamp_end, organization_id}).then(easyUnpack)
        }
    },
    get_video: {
        f: function get_video(session_id) {
            let url = '/data-session/$session_id/video';
            let parameterNames = ["$session_id"];
            [session_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    generate_video: {
        f: function generate_video(session_id, boat_id, camera_id) {
            let url = '/data-session/$session_id/video';
            let parameterNames = ["$session_id"];
            [session_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.post(url, {boat_id, camera_id}).then(easyUnpack)
        }
    },
    delete_session: {
        f: function delete_session(session_id) {
            let url = '/data-session/$session_id';
            let parameterNames = ["$session_id"];
            [session_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.delete(url, {data: {}}).then(easyUnpack)
        }
    },
};
