import React, {useContext} from 'react';
import LineChart from "../../../component/plots/LineChart";
import './session-summary.css';
import SessionDataTable from "../SessionDataTable";
import ProteusMap from "../../../component/proteus-map/ProteusMap";
import AnnotationManager from "../annotation/AnnotationManager";

export default function SessionSummary({
                                           session,
                                           filteredSessionData,
                                           parameters,
                                           dataDescription,
                                           traveledDistance,
                                       }) {
    if (!filteredSessionData) {
        return <div>No data available</div>;
    }

    return (
        <div className='summary'>
            <div className='summary-header'>
                <h2>{session.name}</h2>
                <sub>{session.session_start_timestamp} - {session.session_end_timestamp}</sub>
            </div>
            {filteredSessionData && filteredSessionData.length > 0 && <div className='map-video-wrapper'>
                <ProteusMap filteredSessionData={filteredSessionData}
                            displaySettings={false}
                            height={'45vh'}
                            allow_pan={false}
                            showLegend={false}
                            displayKeys={parameters.filter(p => !p.dont_plot).map(k => k.key)}
                />
            </div>}
            <table className='details'>
                <tbody>
                <tr>
                    <th>Samlet distance:</th>
                    <th>Datapunkter:</th>
                    <th>Fartøj:</th>
                    <th>Dybde:</th>
                    <th>Operatør:</th>
                </tr>
                <tr>
                    <td>{Math.round(traveledDistance * 100) / 100} km</td>
                    <td>{filteredSessionData.length}</td>
                    <td>SenseBOAT Sally I</td>
                    <td>35cm</td>
                    <td>Jakob Brömauer</td>
                </tr>
                </tbody>
            </table>
            <div className='page-break' style={{
                pageBreakAfter: 'always',
                clear: 'both',
            }}></div>

            <div className='page-break' style={{
                pageBreakAfter: 'always',
                clear: 'both',
            }}></div>
            <div className='graphs'>
                {parameters.filter(p => !p.dont_plot).map(({title, key, metric}) => {
                    const description = dataDescription ? dataDescription[key] : null;
                    const vals = filteredSessionData.map(d => d.properties[key]).sort((a, b) => a - b);
                    const mean = Math.floor(vals.reduce((a, b) => a + b, 0) / filteredSessionData.length * 100) / 100;
                    const min = vals[0];
                    const max = vals[vals.length - 1];

                    return (
                        <div key={key} className='parameter-wrapper'>
                            <label><h2>{title}</h2>{description.unit && `(${description.unit})`}</label>
                            <div className='horizontal'>
                                <div className='key-number-wrapper'>
                                    <div className='key-number'>
                                        <label>Gennemsnit: {mean} {description && description.unit}</label></div>
                                    <div className='key-number'>
                                        <label>Min: {min} {description && description.unit}</label></div>
                                    <div className='key-number'>
                                        <label>Max: {max} {description && description.unit}</label></div>
                                    <LineChart
                                        id={key}
                                        width={300}
                                        height={150}
                                        data_key={key}
                                        all_data={filteredSessionData}
                                        mean={metric?.mean}
                                        std_deviation={metric?.std_deviation}
                                    />
                                </div>
                                {metric && (
                                    <>
                                        <div className='key-number-wrapper metric'>
                                            <h4>Water profile</h4>
                                            <div className='key-number'><label>Mean: {metric.mean}</label></div>
                                            <div className='key-number'><label>Std
                                                deviation: {metric.std_deviation}</label>
                                            </div>
                                        </div>
                                        <div className='key-number-wrapper metric'>
                                            <h4>Trip comparison</h4>
                                            <div className='key-number'>
                                                <label>Gennemsnit
                                                    Diff: {Math.floor((mean - metric.mean) * 10) / 10}{description && description.unit}</label>
                                            </div>
                                            <div className='key-number'>
                                                <label>Min
                                                    Diff: {Math.floor((min - metric.mean) * 10) / 10}{description && description.unit}</label>
                                            </div>
                                            <div className='key-number'>
                                                <label>Max
                                                    Diff: {Math.floor((max - metric.mean) * 10) / 10}{description && description.unit}</label>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='page-break' style={{
                pageBreakAfter: 'always',
                clear: 'both',
            }}></div>
            <h1>Bemærkninger</h1>
            <AnnotationManager />

            <div className='page-break' style={{
                pageBreakAfter: 'always',
                clear: 'both',
            }}></div>
            <h1>Komplet dataset</h1>
            <p>I kan også finde en csv fil med alle data i samme folder som dette dokument.</p>
            <SessionDataTable filteredSessionData={filteredSessionData}
                              parameters={parameters}
            />
        </div>
    );
}
