import React, {useContext} from "react";
import SortableTable from "../../component/sortable-table/SortableTable";
import {getUTCDateAtFaceValue, prettyTimestampFromDate} from "../../helpers";
import {SessionContext} from "./SessionContextProvider";
import {useSessionData} from "./useSessionData";

export default function SessionDataTable({filteredSessionData, parameters, dataDescription}) {
    const {
        setHoveringX,
        selectedDataItem,
        setSelectedDataItem,
    } = useContext(SessionContext);

    const {
        itemColors,
    } = useSessionData();

    if (!filteredSessionData) return null;

    return (<SortableTable
        items={filteredSessionData}
        fields={parameters.map(param => {
            let unit = ''
            if (dataDescription && param.key in dataDescription) {
                unit = dataDescription[param.key].unit;
            }
            let title = param.title;
            if (title === 'Tryptophan') {
                title = 'Tryp.';
            } else if (title === 'Turbidity') {
                title = 'Turb.';
            } else if (title === 'Conductivity') {
                title = 'Conduc.';
            } else if (title === 'Temperature') {
                title = 'Temp.';
            }
            return {
                key: param.key,
                title: `${title} ${unit ? `(${unit})` : ''}`,
                metric: param.metric,
            }
        })}
        customRenderFunction={(item, idx) => {
            return (<tr key={item.id || idx}
                        onClick={_ => setSelectedDataItem(item)}
                        id={`row-${item.id}`}
                        className={selectedDataItem && item.id === selectedDataItem.id ? 'selected' : ''}>
                {parameters.map(param => {
                    return <td key={param.key}
                               style={{
                                   background: itemColors ? itemColors[item.id].background : 'white',
                                   color: itemColors ? itemColors[item.id].color : 'black',
                               }}
                               onMouseEnter={_ => setHoveringX(item)}>
                        {param.key === 'timestamp' ? prettyTimestampFromDate(getUTCDateAtFaceValue(item.properties.timestamp)) : item.properties[param.key]}
                    </td>
                })}
            </tr>)
        }}
    />)
}